import React from "react";

import EmptyData from "../EmptyData";
import { toPesoAmount } from "../../helpers";
import PacmanWrapper from "./PacmanWrapper";
import useFetch from "../../hooks/useFetch";

const CardList = ({
  params = {},
  header = "",
  mapFunction,
  className = "",
}) => {
  const reqObj = {
    url: "/dashboard/sales-by-payment/type",
    method: "post",
    body: params,
  };

  const { data, isLoading } = useFetch(reqObj, {});
  const items = data || [];

  return (
    <>
      {isLoading || data === null ? (
        <PacmanWrapper />
      ) : (
        <div className={`analytics-card-list h-100 font-size-md ${className}`}>
          {header && <h4 className="pt-8 px-8">{header}</h4>}

          <div className="card-body">
            {items.length <= 0 ? (
              <EmptyData className="text-center mt-12" />
            ) : (
              <>
                <ul className="mt-2 p-0">{items.map(mapFunction)}</ul>

                {params.isTotalVisible && (
                  <h5 className="text-right border-top border-dark-75 w-75 font-weight-bold ml-auto px-4 pt-4">
                    {toPesoAmount(
                      items.reduce((total, cur) => total + cur.sales, 0),
                    )}
                  </h5>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CardList;
