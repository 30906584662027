import React from "react";
import { Waypoint } from "react-waypoint";
import computeScrollIntoView from "scroll-into-view-if-needed";
import {
  IconTooltip,
  InfoAlert,
  SecondaryAlert,
} from "../../../components/Base";
import { MEAL_PLAN_COLOR } from "../../../constants";
import ItemDisplay from "./ItemDisplay";
import ht from "../../../helpertexts";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FixedSizeList as List } from "react-window";
import { AutoSizer } from "react-virtualized";
import { pluralize } from "../../../helpers";
import { Virtuoso } from "react-virtuoso";
import PerfectScrollbar from "react-perfect-scrollbar";

export const CategoryRow = (props) => {
  const {
    category,
    items_list,
    actions,
    website_theme,
    isMealPlan,
    store,
    tagSettings,
    isMenuPreview = false,
  } = props;
  const {
    category_name = "",
    category_position = 0,
    items = [],
    visible_item_count = 0,
    isExclusive = false,
    available = false,
    remaining_items = 0,
  } = category;

  return (
    <Waypoint
      onEnter={() => {
        if (!isMenuPreview) {
          const items = document.querySelectorAll(".nav-link");

          const activeItem = Array.from(items).find(
            (item) => item.textContent === category_name,
          );
          items.forEach((item) => (item.style.color = "#B5B5C3"));

          if (activeItem) {
            activeItem.style.color = website_theme.link_text_color;

            computeScrollIntoView(activeItem, {
              scrollMode: "if-needed",
              inline: "end",
              boundary: document.querySelector(".category-menu-nav"),
            });
          }
        }
      }}
      fireOnRapidScroll={true}
      topOffset="10%"
    >
      <div className="bw-iac-item" id={`category${category_position}`}>
        {visible_item_count > 0 && (
          <>
            <div
              className="bw-iac-item--category-name"
              style={{
                color: website_theme.category_header_text_color,
                borderBottomColor: website_theme.category_header_text_color,
              }}
            >
              <span className="va-middle">
                <span className="va-middle">{category_name}</span>
                {isMealPlan && isExclusive && (
                  <span className="va-middle">
                    <span className="label label-meal-plan label-inline font-weight-bolder text-uppercase">
                      exclusive
                    </span>
                  </span>
                )}
              </span>

              {available && !isMealPlan ? (
                remaining_items <= 10 && remaining_items > -1 ? (
                  <span className="label label-danger label-inline font-weight-bold ml-2">
                    {" "}
                    {pluralize(remaining_items, "item")} left!
                  </span>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </div>

            {isMealPlan && isExclusive && (
              <div
                className="alert alert-custom alert-outline-dark fade show mb-5"
                role="alert"
                style={{ borderColor: MEAL_PLAN_COLOR }}
              >
                <div className="alert-text" style={{ color: MEAL_PLAN_COLOR }}>
                  {ht["branded-website"].mealplan_exclusive}
                </div>
              </div>
            )}

            <div className="bw-iac-item--items-wrapper">
              {items.map((id, index) => {
                const item = items_list[id];

                return (
                  <ItemDisplay
                    isMenuPreview={isMenuPreview}
                    item={item}
                    categoryLimit={remaining_items}
                    // actions={this.props.actions}
                    actions={actions}
                    available={item.available && !item.off_date}
                    visible={
                      typeof item.visible !== "undefined"
                        ? item.visible || item.isVisibleOnOffDate
                        : true
                    }
                    website_theme={website_theme}
                    hotItem={item.hotItem}
                    tagSettings={tagSettings}
                    key={index}
                    isMealPlan={isMealPlan}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </Waypoint>
  );
};

const ItemsDisplay = (props) => {
  const {
    categories = [],
    items_list,
    website_theme = {},
    isMealPlan = false,
    store = {},
    actions,
    listRef,
  } = props;
  const tagSettings =
    store.tag_settings &&
    typeof store.tag_settings === "string" &&
    store.tag_settings !== ""
      ? JSON.parse(store.tag_settings)
      : {};
  const rowProps = {
    items_list,
    website_theme,
    isMealPlan,
    store,
    tagSettings,
    actions,
  };
  if (window.ReactNativeWebView) {
    // if(true){
    return (
      <AutoSizer>
        {({ height, width }) => {
          return (
            <div className={"bw-iac-wrapper"} style={{ height, width }}>
              <PerfectScrollbar ref={listRef}>
                {categories.map((category, index) => {
                  return (
                    <CategoryRow
                      key={index + "cat_key"}
                      category={category}
                      {...rowProps}
                    />
                  );
                })}
              </PerfectScrollbar>
            </div>
          );
        }}
      </AutoSizer>
    );
  } else {
    return (
      <Virtuoso
        ref={listRef}
        style={{ height: "100%" }}
        data={categories}
        className={"bw-iac-wrapper"}
        itemContent={(index, category) => (
          <CategoryRow category={category} {...rowProps} />
        )}
      />
    );
  }
};

export default ItemsDisplay;
