import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import helpertexts from "../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import {
  HeaderTextWithActions,
  InfoAlertHelper,
  Label,
  PreviewPhoto,
} from "../../components/Base";
import { Button, Card, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import Breadcrumbs from "../../components/Breadcrumbs";
import { GetDataFromEvent, GetSubmitClassList } from "../../helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PRIMARY_COLOR } from "../../constants";
import ImageUpload from "../../components/ImageUpload";
import SelectionModal from "../../components/widgets/SelectionModal";

import ConfirmPasswordModal from "../../partials/ConfirmPasswordModal";
import helpertexts from "../../helpertexts";
import FormModal from "../../components/FormModal";
import SetStoreHoursModal from "../../partials/SetStoreHoursModal";
import SetOffDatesModal from "../../partials/SetOffDatesModal";
import PreorderSettingsModal from "../../partials/PreorderSettingsModal";
import InAdvancedPreOrderModal from "../../partials/InAdvancedPreOrderModal";
import BrandSettingsDiv from "../../partials/BrandSettingsDiv";
import PaymentSettingsDiv from "../../partials/PaymentSettingsDiv";
import DeliverySettingsDiv from "../../partials/DeliverySettingsDiv";
import EditPaymentMethodsModal from "../../partials/EditPaymentMethodsModal";
import BrandNameModal from "../../partials/BrandNameModal";
import LogoFormDiv from "../../partials/LogoFormDiv";
import StoreTitleFormDiv from "../../partials/StoreTitleFormDiv";
import ThemeColorsFormDiv from "../../partials/ThemeColorsFormDiv";
import HeaderAndMenuFormDiv from "../../partials/HeaderAndMenuFormDiv";
import AboutContentFormDiv from "../../partials/AboutContentFormDiv";
import HomepageContentFormDiv from "../../partials/HomepageContentFormDiv";
import WebsiteStatusFormDiv from "../../partials/WebsiteStatusFormDiv";
import PreviewCropper from "../../components/widgets/PreviewCropper";
import Activities from "../../partials/Activities";
import { isEmpty } from "lodash";
import { getUserByToken } from "../../modules/Auth/_redux/authCrud";
import { actionTypes } from "../../modules/Auth/_redux/authRedux";

const MySwal = withReactContent(Swal);

const MENUS = {
  main: "main",
  logo: "logo",
  title: "title",
  theme: "theme",
  header: "header",
  about: "about",
  homepage: "homepage",
  display: "display",
  payment: "payment",
  delivery: "delivery",
  status: "status",
  admin_delivery: "admin_delivery",
  activity: "activity",
};

export default function Page({ history }) {
  const params = useParams(),
    { user } = useSelector((state) => state.auth),
    { brand_url = "", mode = "" } = params;
  const location = useLocation();
  const dispatch = useDispatch();
  const refreshUserRedux = () => {
    getUserByToken()
      .then(({ data }) => {
        dispatch({ type: actionTypes.UserLoaded, payload: { user: data } });
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: `Something went wrong`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      });
  };
  return (
    <MainComponent
      user={user}
      brand_url={brand_url}
      webview={mode === "webview"}
      history={history}
      {...params}
      {...location}
      refreshUserRedux={refreshUserRedux}
    />
  );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "",
      form_modal: {},
      uploadImageFile: "",
      previewUploadImageFile: "",
      croppedImage: "",
      website_status_update: "",
      isFromStoreStatus: false,
      activeMenuKey:
        MENUS[this.props.history.location.pathname.split("/").pop()] ||
        MENUS.main,
    };

    this.api = core("pu").get();
    this.fileapi = core("puf").get();

    this.data_url = "/brand/u/" + this.props.brand_url + "/settings";

    this.getHelperText = this.getHelperText.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.showFormModal = this.showFormModal.bind(this);
    this.handleOnSubmitEditPaymentMethod =
      this.handleOnSubmitEditPaymentMethod.bind(this);
    this.handleOnClickRemoveBackgroundImage =
      this.handleOnClickRemoveBackgroundImage.bind(this);
    this.handleOnSubmitUploadBackgroundImage =
      this.handleOnSubmitUploadBackgroundImage.bind(this);
    this.handleOnClickWebsiteStatusBtn =
      this.handleOnClickWebsiteStatusBtn.bind(this);
    this.handleOnSubmitWebsitePrivacyUpdate =
      this.handleOnSubmitWebsitePrivacyUpdate.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.updateBrand = this.updateBrand.bind(this);
    this.getLoggedInUser = this.getLoggedInUser.bind(this);
    this.onSubmitThemeColorsForm = this.onSubmitThemeColorsForm.bind(this);
    this.onSubmitWebsiteContentForm =
      this.onSubmitWebsiteContentForm.bind(this);
    this.onSubmitAboutPageContentForm =
      this.onSubmitAboutPageContentForm.bind(this);
  }

  handleError = (error, maxFileSizeText = "200KB") => {
    if (error === "size-too-much") {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `File is too big! Maximum upload size is ${maxFileSizeText}.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else if (error === "unsupported-file-extension") {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `File type unsupported. Please upload PNG, JPG and JPEG files only.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else if (error === undefined) {
      /** do nothing */
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `Something went wrong`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };

  getHelperText = (field) => {
    return helpertexts["getting-started"][field];
  };

  handleOnDataFetched = () => {
    this.setState({ mountDisplaySettings: false });

    let { data = {} } = this.state,
      {
        name = "",
        payment_gateway,
        website_theme = "",
        website_content = "",
      } = data;

    data.website_theme = website_theme !== "" ? JSON.parse(website_theme) : {};
    data.website_content =
      website_content !== "" ? JSON.parse(website_content) : {};

    const hasPaymentGateway =
      payment_gateway !== "" && payment_gateway !== null;

    this.setState({
      title: name + ": Settings",
      mountDisplaySettings: true,
      showNoPaymentDetailsModal: !hasPaymentGateway,
      hasPaymentGateway,
    });
  };

  onSubmit = (values, setSubmitting) => {
    const { _id = "" } = this.state.data;

    this.api
      .put({
        url: "/brand/" + _id,
        data: values,
      })
      .then(() => {
        setSubmitting(false);
        this.setState({
          showFormModal: false,
          showStoreHoursModal: false,
          showOffDatesModal: false,
          showInAdvancedPreOrderModal: false,
          showPreorderSettingsModal: false,
        });

        this.getData();

        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully saved your changes.",
          confirmButtonColor: PRIMARY_COLOR,
          showConfirmButton: false,
          timer: 500,
        });
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  showFormModal = (name = "") => {
    let form_modal = {},
      { data = {} } = this.state;

    form_modal.name = name;
    form_modal.value = data[name];
    form_modal.onSubmit = this.onSubmit;

    if (name === "name") {
      // store name
      form_modal.modal_title = "Edit brand name";
      form_modal.form_item = {
        label: "Brand Name",
        showRequired: true,
        helperText: this.getHelperText("store_name"),
      };
    }
    // } else if (name === "location") {
    //     // store location
    //     form_modal.modal_title = "Edit store location";
    //     form_modal.form_item = {
    //         label: "Location",
    //         showRequired: true,
    //         helperText: this.getHelperText("store_location"),
    //     };
    // } else if (name === "contact_number") {
    //     // store contact number
    //     form_modal.modal_title = "Edit store contact number";
    //     form_modal.form_item = {
    //         label: "Contact Number",
    //         showRequired: true,
    //         helperText: this.getHelperText("store_contact_number"),
    //     };
    // } else if (name === "is_accepting_tips") {
    //     form_modal.modal_title = "Edit tips";
    //     form_modal.type = "toggle";
    //     form_modal.form_item = {
    //         label: "Enable tips:",
    //     };
    //     form_modal.info_helper = <InfoAlertHelper text={this.getHelperText("store_tips")} />;
    // } else if (name === "is_accepting_preorders") {
    //     form_modal.modal_title = "Edit day-advanced pre-orders";
    //     form_modal.type = "toggle";
    //     form_modal.form_item = {
    //         label: "Accept day-advanced pre-orders?",
    //     };
    //     form_modal.info_helper = <InfoAlertHelper text={this.getHelperText("is_accepting_preorders")} />;
    // } else if (name === "notify_store_before_pickup_time") {
    //     form_modal.modal_title = "Edit pre-order settings";
    //     form_modal.type = "number";
    //     form_modal.form_item = {
    //         label: "Pre-order pickup time notification allowance",
    //         showRequired: true,
    //         helperText: "Set your preferred notification allowance in minutes.",
    //     };

    //     form_modal.info_helper = <InfoAlertHelper text={this.getHelperText("notify_store_before_pickup_time")} />;
    // }

    this.setState({ mountFormModal: false, form_modal });
    setTimeout(() => {
      this.setState({ mountFormModal: true, showFormModal: true });
    });
  };

  getLoggedInUser = (field = "fullname") => {
    const { user = {} } = this.props;
    if (field === "fullname") {
      return user.first_name + " " + user.last_name;
    } else {
      return user[field];
    }
  };

  uploadFile = (file, setSubmitting) => {
    this.setState({ updating: true });

    const { uploadImageMode = "" } = this.state;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("storeId", this.props.id);
    formData.append("uploadImageMode", uploadImageMode);

    this.fileapi
      .post({
        url: "/util/upload-to-aws/brands",
        data: formData,
      })
      .then(({ data }) => {
        const { filePath = "" } = data;
        if (uploadImageMode === "background") {
          this.updateBrand(
            { brand_website_bg: filePath },
            () => {
              this.setState({
                showUploadBackgroundImageModal: false,
                updating: false,
              });
            },
            this.handleError(),
          );
        } else if (uploadImageMode === "brand_logo") {
          this.updateBrand(
            { brand_logo: filePath },
            () => {
              this.setState({
                showUploadBrandLogoModal: false,
                updating: false,
              });
            },
            this.handleError(),
          );
        } else if (uploadImageMode === "navbar_logo") {
          this.updateBrand(
            { navbar_logo: filePath },
            () => {
              this.setState({
                showUploadNavbarLogoModal: false,
                updating: false,
              });
            },
            this.handleError(),
          );
        }
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  updateBrand(
    updateParams = {},
    handleOnSuccess = () => {},
    handleOnError = () => {},
  ) {
    const { _id = "" } = this.state.data;
    this.api
      .put({
        url: "/brand/" + _id,
        data: updateParams,
      })
      .then(() => {
        handleOnSuccess();
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully saved your changes.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 1000,
        });

        this.getData();
        this.setState({
          previewUploadImageFile: "",
          showOrientationModal: false,
        });
      })
      .catch(() => {
        handleOnError();
      });
  }

  onSubmitThemeColorsForm = (values, setSubmitting) => {
    this.updateBrand(
      { website_theme: JSON.stringify(values) },
      () => {
        setSubmitting(false);
      },
      this.handleError(),
    );
  };

  onSubmitWebsiteContentForm = (values, setSubmitting) => {
    this.updateBrand(
      { website_content: JSON.stringify(values) },
      () => {
        setSubmitting(false);
      },
      this.handleError(),
    );
  };

  onSubmitAboutPageContentForm = (
    { brand_description = "" },
    setSubmitting,
  ) => {
    this.updateBrand(
      { brand_description },
      () => {
        setSubmitting(false);
      },
      this.handleError(),
    );
  };

  handleOnSubmitEditPaymentMethod = (values, setSubmitting) => {
    let payment_methods = [];

    for (var key in values) if (values[key]) payment_methods.push(key);

    const { brand = {} } = this.state.data;

    this.api
      .put({
        url: "/brand/" + brand._id,
        data: { payment_methods },
      })
      .then(() => {
        setSubmitting(false);
        this.setState({ showEditPaymentMethodsModal: false });
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully saved your changes.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 1000,
        });

        this.getData();
      })
      .catch(() => {
        this.handleError();
        setSubmitting(false);
      });
  };

  handleOnClickRemoveBackgroundImage = () => {
    MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to remove the current background image.`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ removeBackgroundImageBtnLoading: true });

        this.updateBrand(
          { brand_website_bg: "" },
          () => {
            this.setState({ showBackgroundImageModal: false, updating: false });
          },
          this.handleError(),
        );
      }
    });
  };

  dataUrlToFile = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/jpeg" });
  };

  handleOnSubmitUploadBackgroundImage = () => {
    if (this.state.croppedImage) {
      this.dataUrlToFile(
        this.state.croppedImage,
        this.state.uploadImageFile.name,
      )
        .then((file) => {
          this.uploadFile(file, () => {});
        })
        .catch((err) => console.log(err));
    } else {
      console.log(this.state.uploadImageFile);
      this.uploadFile(this.state.uploadImageFile, () => {});
    }
  };

  handleOnClickWebsiteStatusBtn = (e) => {
    const website_status_update = GetDataFromEvent(e, "data-action");
    if (website_status_update === "public") {
      if (!isEmpty(this.props.state) && this.props.state.storeId) {
        this.setState({
          showPasswordConfirmModal: true,
          website_status_update,
        });
      } else {
        const { data = {} } = this.state;
        this.api
          .get(`/brand/${data._id}/is-valid-for-public-website`)
          .then(({ data }) => {
            const { result = "", valid = false } = data;
            if (result === "ok" && valid) {
              this.setState({
                showPasswordConfirmModal: true,
                website_status_update,
              });
            } else {
              MySwal.fire({
                icon: "error",
                title: "Oooops!",
                text: "You can't set your brand website to public if you don't have any public stores.",
                confirmButtonColor: PRIMARY_COLOR,
                confirmButtonText: "Go to Stores",
              }).then((result) => {
                if (result.value) {
                  this.setState({
                    redirectTo: "/stores",
                  });
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      MySwal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "Unpublishing a brand will unpublish all stores. Do you want to proceed?",
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result) {
          if (result.isConfirmed) {
            this.setState({
              showPasswordConfirmModal: true,
              website_status_update,
            });
          }
        }
      });
    }
  };

  handleOnSubmitWebsitePrivacyUpdate = (values, setSubmitting) => {
    const { website_status_update = "", data = {} } = this.state;
    values.website_status = website_status_update;

    this.api
      .put({
        data: { data: window.btoa(JSON.stringify(values)) },
        url: `/brand/${data._id}/website-status`,
      })
      .then(({ data }) => {
        const { result = "" } = data;
        if (result === "error") {
          MySwal.fire({
            icon: "error",
            title: "Oooops!",
            text: "Incorrect password!",
          });

          setSubmitting(false);
        } else {
          if (!isEmpty(this.props.state) && this.props.state.storeId) {
            this.api
              .put({
                data: { data: window.btoa(JSON.stringify(values)) },
                url: `/store/${this.props.state.storeId}/website-status`,
              })
              .then(({ data }) => {
                const { result = "" } = data;
                if (result !== "error") {
                  MySwal.fire({
                    icon: "success",
                    title: "Success!",
                    text:
                      website_status_update === "public"
                        ? "Your website has been successfully published."
                        : "Your website has been successfully unpublished",
                    confirmButtonColor: PRIMARY_COLOR,
                    confirmButtonText: "Ok",
                    timer: 1000,
                  });
                  setSubmitting(false);

                  this.getData();
                  this.setState({ showPasswordConfirmModal: false });
                  this.props.refreshUserRedux();
                }
              })
              .catch(() => {
                this.handleError();
              });
          } else {
            MySwal.fire({
              icon: "success",
              title: "Success!",
              text:
                website_status_update === "public"
                  ? "Your website has been successfully published."
                  : "Your website has been successfully unpublished",
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "Ok",
              timer: 1000,
            });
            setSubmitting(false);

            this.getData();
            this.setState({ showPasswordConfirmModal: false });
          }
        }
      })
      .catch(() => {
        this.handleError();
      });
  };

  setActiveMenu = (activeMenuKey) => {
    const { webview = false } = this.props;

    this.setState({ activeMenuKey });
    if (webview) {
      this.props.history.push(
        `/webview/brands/${this.props.brand_url}/settings/${
          activeMenuKey !== MENUS.main ? activeMenuKey : ""
        }`,
      );
    } else {
      this.props.history.push(
        `/brands/${this.props.brand_url}/settings/${
          activeMenuKey !== MENUS.main ? activeMenuKey : ""
        }`,
      );
    }
  };

  setCroppedImage = (croppedImage) => {
    this.setState({ croppedImage });
  };

  render() {
    const {
        data = {},
        data_status = "",
        form_modal = {},
        showFormModal = false,
        mountFormModal = false,
        showStoreHoursModal = false,
        showOffDatesModal = false,
        showPreorderSettingsModal = false,
        showInAdvancedPreOrderModal = false,
        mountDisplaySettings = false,
        showEditPaymentGatewayModal = false,
        showEditPaymentMethodsModal = false,
        showBackgroundImageModal = false,
        showUploadBackgroundImageModal = false,
        updating = false,
        showPasswordConfirmModal = false,
        showNoPaymentDetailsModal = false,
        showUploadBrandLogoModal = false,
        showUploadNavbarLogoModal = false,
        showBrandNameModal = false,
        hasPaymentGateway = false,
        showAddDeliveryKeysModal = false,
      } = this.state,
      isLoading = data_status !== "fetched",
      { webview = false } = this.props;

    const display_settings_props = {
      data,
      setState: (state) => {
        this.setState(state);
      },
      handleOnClickWebsiteStatusBtn: this.handleOnClickWebsiteStatusBtn,
      brand_homepage: `/brands/${this.props.brand_url}`,
      onSubmitThemeColorsForm: this.onSubmitThemeColorsForm,
      onSubmitWebsiteContentForm: this.onSubmitWebsiteContentForm,
      onSubmitAboutPageContentForm: this.onSubmitAboutPageContentForm,
    };

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {isLoading ? (
          <></>
        ) : (
          <>
            <HeaderTextWithActions
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item
                    text="Stores"
                    link={`${webview ? "/webview" : ""}/stores`}
                  />
                  <Breadcrumbs.Item
                    text="Brand Settings"
                    active={"true"}
                    link={`${webview ? "/webview" : ""}/brands/${
                      this.props.brand_url
                    }/settings`}
                  />
                </Breadcrumbs>
              }
              title={<>{data.name}</>}
              subtitle={<></>}
            />
            {mountFormModal ? (
              <FormModal
                show={showFormModal}
                onHide={() => {
                  this.setState({ showFormModal: false });
                }}
                {...form_modal}
              />
            ) : (
              <></>
            )}

            <SetStoreHoursModal
              show={showStoreHoursModal}
              onHide={() => {
                this.setState({ showStoreHoursModal: false });
              }}
              data={data}
              onSubmit={this.onSubmit}
            />

            <SetOffDatesModal
              show={showOffDatesModal}
              onHide={() => {
                this.setState({ showOffDatesModal: false });
              }}
              off_dates={data.off_dates}
              onSubmit={this.onSubmit}
            />

            <InAdvancedPreOrderModal
              show={showInAdvancedPreOrderModal}
              onHide={() => {
                this.setState({ showInAdvancedPreOrderModal: false });
              }}
              data={data}
              onSubmit={this.onSubmit}
            />

            <PreorderSettingsModal
              show={showPreorderSettingsModal}
              onHide={() => {
                this.setState({ showPreorderSettingsModal: false });
              }}
              data={data}
              onSubmit={this.onSubmit}
            />

            <EditPaymentMethodsModal
              show={showEditPaymentMethodsModal}
              onHide={() => {
                this.setState({ showEditPaymentMethodsModal: false });
              }}
              data={data.payment_methods}
              onSubmit={this.handleOnSubmitEditPaymentMethod}
            />

            {/* {
                        mountOrientationModal ? <OrientationModal
                            show={showOrientationModal}
                            onHide={() => { this.setState({ showOrientationModal: false })}}
                            edit_orientation_mode={edit_orientation_mode}
                            orientation={getOrientation(data.website_content, edit_orientation_mode)}
                            onSubmit={(orientation, setSubmitting) => {
                                let { website_content = {} } = data.brand;
                                website_content[`${edit_orientation_mode}_orientation`] = orientation;
                                this.onSubmitWebsiteContentForm(website_content, setSubmitting);
                            }}
                        /> : <></>
                    } */}

            {/* <SelectionModal
                        show={showEditLogoSelectionModal}
                        onHide={() => { this.setState({ showEditLogoSelectionModal: false })}}
                        title={<>Edit Brand Logo</>}
                        options={[
                            {
                                title: "upload new image",
                                icon: "Files/Uploaded-file",
                                desc: "",
                                onClick: () => {
                                    this.setState({ showEditLogoSelectionModal: false, showUploadBrandLogoModal: true, uploadImageMode: "brand_logo" })
                                }
                            },
                            {
                                title: "edit orientation",
                                icon: "Text/Align-justify",
                                desc: "",
                                onClick: () => {
                                    this.setState({ mountOrientationModal: false });
                                    setTimeout(() => { this.setState({ showEditLogoSelectionModal: false, showOrientationModal: true, mountOrientationModal: true, edit_orientation_mode: "brand_logo" }) }, 50); 
                                }
                            },
                        ]}
                    /> */}

            <SelectionModal
              show={showBackgroundImageModal}
              onHide={() => {
                this.setState({ showBackgroundImageModal: false });
              }}
              title={<>Edit Custom Homepage Background</>}
              options={[
                {
                  title: "remove",
                  icon: "General/Trash",
                  desc: "",
                  onClick: this.handleOnClickRemoveBackgroundImage,
                },
                {
                  title: "upload new image",
                  icon: "Files/Uploaded-file",
                  desc: "",
                  onClick: () => {
                    this.setState({
                      showUploadBackgroundImageModal: true,
                      showBackgroundImageModal: false,
                    });
                  },
                },
              ]}
            />

            <BrandNameModal
              show={showBrandNameModal}
              onHide={() => {
                this.setState({ showBrandNameModal: false });
              }}
              values={data.brand}
              onSubmit={({ name = "" }, setSubmitting) => {
                this.updateBrand(
                  { name },
                  () => {
                    setSubmitting(false);
                    this.setState({ showBrandNameModal: false });
                  },
                  this.handleError(),
                );
              }}
            />

            <Modal
              centered
              show={showUploadBackgroundImageModal}
              onHide={() => {
                this.setState({
                  showUploadBackgroundImageModal: false,
                  previewUploadImageFile: "",
                  uploadImageFile: "",
                });
              }}
            >
              <Modal.Header>
                <Modal.Title>Upload new background image</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  {this.state.previewUploadImageFile !== "" ? (
                    <>
                      <PreviewCropper
                        image={this.state.previewUploadImageFile}
                        setCroppedImage={this.setCroppedImage}
                      />
                      {/* <PreviewPhoto
                                                src={this.state.previewUploadImageFile}
                                                onClickRemove={() => {
                                                    this.setState({
                                                        uploadImageFile: "",
                                                        previewUploadImageFile: "",
                                                    });
                                                }}
                                                showImage={false}
                                            />
                                            <div> {this.state.uploadImageFile.name} </div> */}
                    </>
                  ) : (
                    <ImageUpload
                      onError={this.handleError}
                      onChange={(file, preview) => {
                        this.setState({
                          uploadImageFile: file,
                          previewUploadImageFile: preview,
                        });
                      }}
                      onRemove={() => {}}
                      removeText={"Remove"}
                      name={"img_upload"}
                      upload_icon={"flaticon-upload"}
                      label={
                        <div style={{ margin: "1rem 0" }}>
                          <Label
                            text={
                              <>
                                <span> Background Image </span>
                              </>
                            }
                          />
                          <div
                            className="text-muted"
                            style={{ fontSize: "0.8rem" }}
                          >
                            Preferred dimensions: 1880 x 1253
                          </div>
                        </div>
                      }
                      useResize={false}
                      maxFileSize="25000"
                      maxFileSizeText="25MB"
                    />
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                {this.state.previewUploadImageFile !== "" && (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.setState({
                        uploadImageFile: "",
                        previewUploadImageFile: "",
                      });
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  variant="primary"
                  disabled={
                    (this.state.previewUploadImageFile !== ""
                      ? ""
                      : "disabled") || (updating ? "disabled" : "")
                  }
                  onClick={this.handleOnSubmitUploadBackgroundImage}
                  className={GetSubmitClassList(updating, "")}
                >
                  Upload
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              centered
              show={showUploadBrandLogoModal}
              onHide={() => {
                this.setState({ showUploadBrandLogoModal: false });
              }}
            >
              <Modal.Header>
                <Modal.Title>Upload new brand logo</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  {this.state.previewUploadImageFile !== "" ? (
                    <>
                      <PreviewPhoto
                        src={this.state.previewUploadImageFile}
                        onClickRemove={() => {
                          this.setState({
                            uploadImageFile: "",
                            previewUploadImageFile: "",
                          });
                        }}
                      />
                      <div> {this.state.uploadImageFile.name} </div>
                    </>
                  ) : (
                    <ImageUpload
                      onError={this.handleError}
                      onChange={(file, preview) => {
                        this.setState({
                          uploadImageFile: file,
                          previewUploadImageFile: preview,
                        });
                      }}
                      onRemove={() => {}}
                      removeText={"Remove"}
                      name={"img_upload"}
                      upload_icon={"flaticon-upload"}
                      label={
                        <div style={{ margin: "1rem 0" }}>
                          <Label
                            text={
                              <>
                                <span> Brand Logo </span>
                              </>
                            }
                          />
                          <div
                            className="text-muted"
                            style={{ fontSize: "0.8rem" }}
                          >
                            Suggested dimensions is 250x250 or 200x50.
                          </div>
                        </div>
                      }
                      useResize={false}
                    />
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  disabled={
                    (this.state.previewUploadImageFile !== ""
                      ? ""
                      : "disabled") || (updating ? "disabled" : "")
                  }
                  onClick={this.handleOnSubmitUploadBackgroundImage}
                  className={GetSubmitClassList(updating, "")}
                >
                  Upload
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              centered
              show={showUploadNavbarLogoModal}
              onHide={() => {
                this.setState({ showUploadNavbarLogoModal: false });
              }}
            >
              <Modal.Header>
                <Modal.Title>Upload new navbar logo</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  {this.state.previewUploadImageFile !== "" ? (
                    <>
                      <PreviewPhoto
                        src={this.state.previewUploadImageFile}
                        onClickRemove={() => {
                          this.setState({
                            uploadImageFile: "",
                            previewUploadImageFile: "",
                          });
                        }}
                      />
                      <div> {this.state.uploadImageFile.name} </div>
                    </>
                  ) : (
                    <ImageUpload
                      onError={this.handleError}
                      onChange={(file, preview) => {
                        this.setState({
                          uploadImageFile: file,
                          previewUploadImageFile: preview,
                        });
                      }}
                      onRemove={() => {}}
                      removeText={"Remove"}
                      name={"img_upload"}
                      upload_icon={"flaticon-upload"}
                      label={
                        <div style={{ margin: "1rem 0" }}>
                          <Label
                            text={
                              <>
                                <span> Navbar Logo </span>
                              </>
                            }
                          />
                          <div
                            className="text-muted"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {/* Preferred dimensions: 1880 x 1253 */}
                          </div>
                        </div>
                      }
                      useResize={false}
                    />
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  disabled={
                    (this.state.previewUploadImageFile !== ""
                      ? ""
                      : "disabled") || (updating ? "disabled" : "")
                  }
                  onClick={this.handleOnSubmitUploadBackgroundImage}
                  className={GetSubmitClassList(updating, "")}
                >
                  Upload
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Update website privacy settings */}
            <ConfirmPasswordModal
              show={showPasswordConfirmModal}
              onHide={() => {
                this.setState({ showPasswordConfirmModal: false });
              }}
              body={
                <>
                  <p className="text-dark">
                    You are about to{" "}
                    {this.state.website_status_update === "private" ? (
                      <>unpublish</>
                    ) : (
                      <>publish</>
                    )}{" "}
                    your site.
                  </p>
                </>
              }
              onSubmit={this.handleOnSubmitWebsitePrivacyUpdate}
            />

            <Modal
              centered
              size="sm"
              show={showNoPaymentDetailsModal}
              onHide={() => {
                this.setState({ showNoPaymentDetailsModal: false });
              }}
            >
              <Modal.Header>
                <Modal.Title>Setup your payment details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Oooops! Looks like you haven't setup your payment details.
                  Please send us a message on support@pickup.ph regarding your
                  PayMongo keys and your desired payment methods so we can set
                  this up for you. Thank you!
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => {
                    this.setState({ showNoPaymentDetailsModal: false });
                  }}
                >
                  Got it!
                </Button>
              </Modal.Footer>
            </Modal>

            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={MENUS.main}
              activeKey={this.state.activeMenuKey}
            >
              <Row>
                <Col sm={3}>
                  <Card>
                    <Card.Body>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.main}
                            onClick={() => this.setActiveMenu(MENUS.main)}
                          >
                            Brand Information
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.logo}
                            onClick={() => this.setActiveMenu(MENUS.logo)}
                          >
                            Brand Logo
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.title}
                            onClick={() => this.setActiveMenu(MENUS.title)}
                          >
                            Homepage Title
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.theme}
                            onClick={() => this.setActiveMenu(MENUS.theme)}
                          >
                            Theme Colours
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.header}
                            onClick={() => this.setActiveMenu(MENUS.header)}
                          >
                            Header and Menu
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.about}
                            onClick={() => this.setActiveMenu(MENUS.about)}
                          >
                            About Us Content
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.homepage}
                            onClick={() => this.setActiveMenu(MENUS.homepage)}
                          >
                            Home Page Content
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                                                    <Nav.Link
                                                        eventKey={MENUS.payment}
                                                        onClick={() => this.setActiveMenu(MENUS.payment)}
                                                    >
                                                        Payment Settings
                                                    </Nav.Link>
                                                </Nav.Item> */}
                        {/* <Nav.Item>
                                                    <Nav.Link
                                                        eventKey={MENUS.delivery}
                                                        onClick={() => this.setActiveMenu(MENUS.delivery)}
                                                    >
                                                        Delivery Settings
                                                    </Nav.Link>
                                                </Nav.Item> */}
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.status}
                            onClick={() => this.setActiveMenu(MENUS.status)}
                          >
                            Website Status
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.activity}
                            onClick={() => this.setActiveMenu(MENUS.activity)}
                          >
                            Activity Log
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey={MENUS.main}>
                      <Card className="card-custom gutter-b">
                        <Card.Header>
                          <Card.Title>Brand Information</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <BrandSettingsDiv
                            user={this.props.user}
                            data={data}
                            showFormModal={this.showFormModal}
                            setState={(state) => {
                              this.setState(state);
                            }}
                            onSubmit={this.onSubmit}
                          />
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.logo}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <LogoFormDiv
                            fields={{ logo: "brand_logo" }}
                            title="Brand Logo"
                            {...display_settings_props}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.title}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <StoreTitleFormDiv {...display_settings_props} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.theme}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <ThemeColorsFormDiv {...display_settings_props} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.header}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <HeaderAndMenuFormDiv
                            fields={{ website_bg: "brand_website_bg" }}
                            {...display_settings_props}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.about}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <AboutContentFormDiv
                            field="brand_description"
                            {...display_settings_props}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.homepage}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <HomepageContentFormDiv {...display_settings_props} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey={MENUS.payment}>
                                            <div className="general-bg-color">
                                                <PaymentSettingsDiv
                                                    data={data}
                                                    showEditPaymentGatewayModal={showEditPaymentGatewayModal}
                                                    setState={(state) => {
                                                        this.setState(state);
                                                    }}
                                                />
                                            </div>
                                        </Tab.Pane> 
                                        <Tab.Pane eventKey={MENUS.delivery}>
                                            <div className="general-bg-color">
                                                <DeliverySettingsDiv
                                                    brand={data.brand}
                                                    store={data}
                                                    showEditPaymentGatewayModal={showEditPaymentGatewayModal}
                                                    showAddDeliveryKeysModal={showAddDeliveryKeysModal}
                                                    setState={(state) => {
                                                        this.setState(state);
                                                    }}
                                                    editable={false}
                                                />
                                            </div>
                                        </Tab.Pane>
                                        */}
                    <Tab.Pane eventKey={MENUS.status}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <WebsiteStatusFormDiv
                            {...display_settings_props}
                            hasPaymentGateway={hasPaymentGateway}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey={MENUS.admin_delivery}>
                                            <div className="general-bg-color">
                                                <DeliverySettingsDiv
                                                    brand={data.brand}
                                                    store={data}
                                                    showEditPaymentGatewayModal={showEditPaymentGatewayModal}
                                                    showAddDeliveryKeysModal={showAddDeliveryKeysModal}
                                                    setState={(state) => {
                                                        this.setState(state);
                                                    }}
                                                    editable={true}
                                                />
                                            </div>
                                        </Tab.Pane>  */}
                    <Tab.Pane eventKey={MENUS.activity}>
                      <div className="general-bg-color">
                        <Card className="card-custom gutter-b">
                          <Card.Header>
                            <Card.Title>Activity Log</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Activities
                              collection="brand"
                              identifier={data._id}
                              api={this.api}
                            />
                          </Card.Body>
                        </Card>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
      </>
    );
  }
}
