import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { Block, KTLink, Label } from "../../components/Base";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { getResizedImage, GetSubmitClassList } from "../../helpers";
import { ChromePicker } from "react-color";
import { Topbar } from "../../../_metronic/layout/components/header/Topbar";
import hexRgb from "hex-rgb";
import rgbHex from "rgb-hex";
import ColorPicker from "../../components/ColorPicker";

export default class HeaderAndMenuForm extends ProSourceForm {
  constructor(props) {
    super(props);

    const { values = {} } = this.props,
      {
        text_color = "",
        highlighted_text_color = "",
        button_color = "",
        button_text_color = "",
        link_text_color = "",
        background_color = "",
        icon_color = "",
        top_navigation_button_color = "",
        top_navigation_button_text_color = "",
        category_header_text_color = "",
        homepage_icon_color = "",
        homepage_button_color = "",
        homepage_button_text_color = "",
        homepage_highlighted_text_color = "",
      } = values;

    this.state = {
      values: {
        text_color,
        highlighted_text_color,
        button_color,
        button_text_color,
        link_text_color,
        background_color,
        icon_color,
        top_navigation_button_color,
        top_navigation_button_text_color,
        category_header_text_color,
        homepage_icon_color,
        homepage_button_color,
        homepage_button_text_color,
        homepage_highlighted_text_color,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      background: hexRgb("#FFFFFF"),
    };

    this.validate = {};

    this.openColorPicker = this.openColorPicker.bind(this);
    this.setSelectedColorToValue = this.setSelectedColorToValue.bind(this);
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  openColorPicker = (selected_name) => {
    const { values = {} } = this.state;
    this.setState({ mountModal: false });
    const selected_color =
      values[selected_name] === "" ? "#FFFFFF" : values[selected_name];
    const { red, green, blue, alpha } = hexRgb(selected_color);
    setTimeout(() => {
      this.setState({
        mountModal: true,
        showModal: true,
        selected_name,
        selected_color,
        background: {
          a: alpha,
          r: red,
          g: green,
          b: blue,
        },
      });
    }, 50);
  };

  setSelectedColorToValue = (e) => {
    e.preventDefault();
    let { values = {}, selected_color = "", selected_name = "" } = this.state;
    values[selected_name] = selected_color;
    this.setState({ values, showModal: false });
  };

  handleChangeComplete = (color) => {
    this.setState({
      selected_color:
        color.rgb.a === 1
          ? color.hex
          : "#" + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a),
      background: color.rgb,
    });
  };

  onResetClick = () => {
    const theme_colors = [
      "text_color",
      "highlighted_text_color",
      "button_color",
      "button_text_color",
      "link_text_color",
      "background_color",
      "icon_color",
      "top_navigation_button_color",
      "top_navigation_button_text_color",
      "category_header_text_color",
      "homepage_icon_color",
      "homepage_button_color",
      "homepage_button_text_color",
      "homepage_highlighted_text_color",
    ];
    const reset_state = {};
    theme_colors.forEach((color) => {
      reset_state[color] = this.props.values[color]
        ? this.props.values[color]
        : "";
    });
    this.setState({
      values: reset_state,
    });
  };

  render() {
    const { showModal = false, mountModal = false, background } = this.state,
      {
        data = {},
        fields: {
          website_bg = data.website_bg ? "website_bg" : "brand_website_bg",
        },
      } = this.props,
      { navbar_logo = "" } = data;
    // console.log(navbar_logo);

    const website_bg_url =
      data[website_bg] !== undefined ? data[website_bg] : "";
    // console.log(website_bg_url);

    const image_preview = getResizedImage(
      website_bg_url,
      "preview",
      data.date_updated || data.date_created,
    );

    return (
      <>
        {mountModal ? (
          <Modal
            size="sm"
            centered
            show={showModal}
            onHide={() => {
              this.setState({ showModal: false });
            }}
          >
            <Modal.Header>
              <Modal.Title>Pick Color</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <ColorPicker
                color={background}
                onChangeComplete={this.handleChangeComplete}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={this.setSelectedColorToValue}>
                Select
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <></>
        )}

        <Card className="card-custom gutter-b">
          <Card.Header>
            <Card.Title>Header and Menu</Card.Title>
          </Card.Header>
          <Form onSubmit={this.handleOnSubmit}>
            <Card.Body>
              <Row>
                <fieldset style={{ border: "1.5px solid", width: "100%" }}>
                  <legend
                    style={{ width: 100, textAlign: "center", fontSize: 16 }}
                  >
                    <Label
                      text={
                        <>
                          <span className="va-middle text-capitalize">
                            preview
                          </span>{" "}
                        </>
                      }
                    />
                  </legend>
                  <Col>
                    <Block
                      content={
                        <>
                          <div className="bw-iac-item" id={`category1`}>
                            <div
                              className="bw-iac-item--category-name"
                              style={{
                                fontSize: "1.5rem",
                                fontWeight: 500,
                                marginBottom: 35,
                                borderBottom: `2px solid ${this.state.values["category_header_text_color"]}`,
                                color:
                                  this.state.values[
                                    "category_header_text_color"
                                  ],
                                borderBottomColor:
                                  this.state.values[
                                    "category_header_text_color"
                                  ],
                              }}
                            >
                              <span className="va-middle">
                                <span className="va-middle">
                                  Your Category Name
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="mv-1rem">
                            <div
                              className="topbar-div-preview"
                              style={{
                                marginTop: -10,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Topbar
                                isPreview
                                showMenuItems
                                preview_theme={{
                                  button_text_color:
                                    this.state.values[
                                      "top_navigation_button_text_color"
                                    ],
                                  button_color:
                                    this.state.values[
                                      "top_navigation_button_color"
                                    ],
                                }}
                              />
                            </div>
                          </div>
                        </>
                      }
                    />
                  </Col>
                </fieldset>
              </Row>
              <Row>
                <Col>
                  <Block
                    // title={<><span className="va-middle">Top Navigation Button Colour</span>  <button type="button" className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle" onClick={() => { this.openColorPicker("top_navigation_button_color")}}><i className="far fa-edit text-primary"/></button></>}
                    content={
                      <div className="mv-1rem">
                        <Label
                          text={
                            <>
                              <span className="va-middle text-capitalize">
                                top navigation button outline colour
                              </span>{" "}
                              <button
                                type="button"
                                className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                onClick={() => {
                                  this.openColorPicker(
                                    "top_navigation_button_color",
                                  );
                                }}
                              >
                                <i className="far fa-edit text-primary" />
                              </button>
                            </>
                          }
                        />
                        <div>
                          <div
                            style={{
                              border: "1px black solid",
                              marginRight: "2px",
                              height: "1rem",
                              width: "1rem",
                              display: "inline-block",
                              backgroundColor:
                                this.state.values[
                                  "top_navigation_button_color"
                                ],
                            }}
                          ></div>
                          <div style={{ display: "inline-block" }}>
                            {this.state.values["top_navigation_button_color"]}{" "}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Col>
                <Col>
                  <Block
                    // title={<><span className="va-middle">Top Navigation Button Text Colour</span>  <button type="button" className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle" onClick={() => { this.openColorPicker("top_navigation_button_text_color")}}><i className="far fa-edit text-primary"/></button></>}
                    content={
                      <div className="mv-1rem">
                        <Label
                          text={
                            <>
                              <span className="va-middle text-capitalize">
                                top navigation text colour
                              </span>{" "}
                              <button
                                type="button"
                                className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                onClick={() => {
                                  this.openColorPicker(
                                    "top_navigation_button_text_color",
                                  );
                                }}
                              >
                                <i className="far fa-edit text-primary" />
                              </button>
                            </>
                          }
                        />
                        <div>
                          <div
                            style={{
                              border: "1px black solid",
                              marginRight: "2px",
                              height: "1rem",
                              width: "1rem",
                              display: "inline-block",
                              backgroundColor:
                                this.state.values[
                                  "top_navigation_button_text_color"
                                ],
                            }}
                          ></div>
                          <div style={{ display: "inline-block" }}>
                            {
                              this.state.values[
                                "top_navigation_button_text_color"
                              ]
                            }{" "}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Block
                    // title={<><span className="va-middle">Category Header Text Colour</span>  <button type="button" className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle" onClick={() => { this.openColorPicker("category_header_text_color")}}><i className="far fa-edit text-primary"/></button></>}
                    content={
                      <div className="mv-1rem">
                        <Label
                          text={
                            <>
                              <span className="va-middle text-capitalize">
                                category theme colour
                              </span>{" "}
                              <button
                                type="button"
                                className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                onClick={() => {
                                  this.openColorPicker(
                                    "category_header_text_color",
                                  );
                                }}
                              >
                                <i className="far fa-edit text-primary" />
                              </button>
                            </>
                          }
                        />
                        <div>
                          <div
                            style={{
                              border: "1px black solid",
                              marginRight: "2px",
                              height: "1rem",
                              width: "1rem",
                              display: "inline-block",
                              backgroundColor:
                                this.state.values["category_header_text_color"],
                            }}
                          ></div>
                          <div style={{ display: "inline-block" }}>
                            {this.state.values["category_header_text_color"]}{" "}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Block
                    // title={<><span className="va-middle">navbar logo</span> {(navbar_logo !== "") ? <button type="button" className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle" onClick={() => { this.props.setState({ showUploadNavbarLogoModal: true, uploadImageMode: "navbar_logo" })}}><i className="far fa-edit text-primary"/></button>: <></> }</>}
                    content={
                      <div className="mv-1rem">
                        <Label
                          text={
                            <>
                              <span className="va-middle text-capitalize">
                                navbar logo
                              </span>{" "}
                              {navbar_logo !== "" ? (
                                <button
                                  type="button"
                                  className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                  onClick={() => {
                                    this.props.setState({
                                      showUploadNavbarLogoModal: true,
                                      uploadImageMode: "navbar_logo",
                                    });
                                  }}
                                >
                                  <i className="far fa-edit text-primary" />
                                </button>
                              ) : (
                                <></>
                              )}
                            </>
                          }
                        />
                        <div>
                          {navbar_logo !== "" ? (
                            <>
                              <img
                                src={navbar_logo}
                                alt="logo"
                                className="bw-logo mr-2"
                              />
                            </>
                          ) : (
                            <>
                              <p>
                                <span>
                                  This website doesn't have a navbar logo.
                                  Pickup will use the brand logo as the default.
                                </span>{" "}
                                <KTLink
                                  text={"Do you want to add one?"}
                                  onClick={() => {
                                    this.props.setState({
                                      showUploadNavbarLogoModal: true,
                                      uploadImageMode: "navbar_logo",
                                    });
                                  }}
                                />
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Block
                    // title={<><span className="va-middle">homepage background</span> {(website_bg_url !== "") ? <button type="button" className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle" onClick={() => { this.props.setState({ showBackgroundImageModal: true, uploadImageMode: "background" })}}><i className="far fa-edit text-primary"/></button>: <></> }</>}
                    content={
                      <div className="mv-1rem">
                        <Label
                          text={
                            <>
                              <span className="va-middle text-capitalize">
                                homepage background
                              </span>{" "}
                              {website_bg_url !== "" ? (
                                <button
                                  type="button"
                                  className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                  onClick={() => {
                                    this.props.setState({
                                      showBackgroundImageModal: true,
                                      uploadImageMode: "background",
                                    });
                                  }}
                                >
                                  <i className="far fa-edit text-primary" />
                                </button>
                              ) : (
                                <></>
                              )}
                            </>
                          }
                        />
                        <div className="mt-5">
                          {website_bg_url !== "" ? (
                            <img
                              src={image_preview}
                              alt="brand_website_bg"
                              className="img-fluid clickable brand-website-bg-preview"
                              onClick={() => {
                                this.props.setState({
                                  showBackgroundImageModal: true,
                                  uploadImageMode: "background",
                                });
                              }}
                              onError={(ev) => {
                                ev.target.onError = null;
                                ev.target.src = website_bg_url;
                              }}
                            />
                          ) : (
                            <>
                              <p>
                                <span>
                                  Website currently doesn't have a custom
                                  background.{" "}
                                </span>{" "}
                                <KTLink
                                  text={"Do you want to add one?"}
                                  onClick={() => {
                                    this.props.setState({
                                      showUploadBackgroundImageModal: true,
                                      uploadImageMode: "background",
                                    });
                                  }}
                                />
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    }
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="primary"
                type="submit"
                className={GetSubmitClassList(this.state.isSubmitting, "")}
                style={{ marginRight: 10 }}
              >
                Save Changes
              </Button>
              <Button
                variant="primary"
                type="button"
                className={GetSubmitClassList(this.state.isSubmitting, "")}
                onClick={this.onResetClick}
              >
                Reset
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      </>
    );
  }
}
