import React from "react";
import { KTUtil } from "../../../../_metronic/_assets/js/components/util";
import { IconTooltip, InfoTooltip } from "../../../components/Base";
import ItemTag from "../../../components/widgets/ItemTag";
import MenuImage from "../../../components/widgets/MenuImage";
import { getResizedImage, pluralize, toPesoAmount } from "../../../helpers";
import { TAG_NAMES } from "../../forms/ItemTags";
import styled from "styled-components";

const CustomOutlineButton = styled.button`
  border-color: ${({ button_color }) => button_color};
  color: ${({ button_color }) => button_color};

  &:disabled {
    opacity: unset;
    color: ${({ button_text_color }) => button_text_color};
    font-weight: 500;
    background-color: ${({ button_color }) => button_color};
  }

  &:hover {
    color: ${({ button_text_color }) => button_text_color};
    font-weight: 500;
    background-color: ${({ button_color }) => button_color};
  }
`;

/**
 *
 * @param {*} props.item
 * @param {*} props.available_count_slot
 * @param {Boolean} props.isMealPlan
 * @returns
 */
export default function ItemDisplay(props) {
  const {
      item = {},
      available = false,
      visible = true,
      website_theme = {},
      tagSettings,
      isMealPlan = false,
      categoryLimit = 0,
      isMenuPreview = false,
    } = props,
    {
      name = "",
      description = "",
      price = "",
      image_url = "",
      _id = "",
      date_updated,
      date_created,
      active_discount = "",
      regular_price = "",
      isBulk,
      discount_description = "",
      discount_type = "",
      discount_value = "",
      isDineInExclusive = false,
    } = item;

  let activeTags = null;

  try {
    // const settings = tagSettings && typeof tagSettings === 'string' ? JSON.parse(tagSettings) : undefined
    activeTags = tagSettings
      ? item.tags.map((tagId, index) => {
          return typeof tagSettings[tagId] !== "undefined" ? (
            <div className="mr-3" key={index}>
              <ItemTag
                backgroundColor={tagSettings[tagId].bgColor}
                textColor={tagSettings[tagId].textColor}
                borderColor={tagSettings[tagId].borderColor}
                icon={tagSettings[tagId].icon}
                name={TAG_NAMES[tagId]}
              />
            </div>
          ) : null;
        })
      : null;
  } catch (error) {}

  const {
    button_text_color = "",
    button_color = "",
    category_header_text_color = "",
  } = website_theme;

  const small_image = getResizedImage(
    image_url,
    "small",
    date_updated || date_created,
  );
  const medium_image = getResizedImage(
    image_url,
    "medium",
    date_updated || date_created,
  );

  const onClickItemDisplay = () => {
    props.actions.setSelectedItem(item, available, activeTags);
  };

  return (
    Boolean(available || visible) && (
      <div className="bw-iac-item--item-wrapper category-menu-card">
        <div
          className={`item mb-0 w-100 ${
            Boolean(!available && visible) ? "white-out" : ""
          }`}
          data-id={_id}
          onClick={onClickItemDisplay}
        >
          <div className="d-flex align-items-center">
            <div
              className="menu-image-container mr-4"
              style={{ position: "relative" }}
            >
              <MenuImage
                src={KTUtil.isMobileDevice() ? medium_image : small_image}
                backupSrc={image_url}
                alt={name}
              />
              {active_discount > 0 && !isMealPlan ? (
                discount_type === "percentDiscount" ? (
                  <div className="discount-style">
                    {Number(discount_value)}% OFF
                  </div>
                ) : discount_type === "rawDiscount" ? (
                  <div className="discount-style">
                    P {Number(active_discount).toFixed(2)} OFF
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </div>
            <div className="item-text-wrapper d-flex flex-column flex-grow-1 pr-2">
              <div className="item-name d-flex">
                {name}
                {isBulk ? (
                  <div>
                    <IconTooltip
                      infoText="This item is bulky and can only be delivered by cars"
                      iconFAClass="fas fa-truck-loading"
                      iconColor="lightsalmon"
                    />
                  </div>
                ) : (
                  <></>
                )}
                {!available ? (
                  <span className="label label-danger label-inline font-weight-bold ml-2 un-tag">
                    UNAVAILABLE
                  </span>
                ) : (
                  <></>
                )}
              </div>
              {/* <div className="item-desc">{description}</div> */}
              <div className="item-desc category-menu-item-desc mr-2">
                {description}
              </div>
              {!isMealPlan && (
                <>
                  {active_discount > 0 ? (
                    <div className="item-price">
                      <del>{toPesoAmount(regular_price)}</del>
                      <span className="ml-3 text-danger font-weight-boldest">
                        {toPesoAmount(price)}
                      </span>
                    </div>
                  ) : (
                    <div
                      className="item-price"
                      style={{
                        color: KTUtil.isMobileDevice()
                          ? category_header_text_color
                          : "",
                      }}
                    >
                      {toPesoAmount(price)}
                    </div>
                  )}
                  {discount_description && active_discount > 0 ? (
                    <div className="item-disc-desc text-danger">
                      {discount_description}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}

              {available ? (
                <div className="item-limit">
                  {item.remaining_items <= 10 && item.remaining_items > -1 ? (
                    <span className="text-danger">
                      {" "}
                      {categoryLimit <= item.remaining_items &&
                      categoryLimit > -1
                        ? pluralize(categoryLimit, "item")
                        : pluralize(item.remaining_items, "item")}{" "}
                      left!
                    </span>
                  ) : (
                    <></>
                  )}{" "}
                </div>
              ) : (
                <></>
              )}
              {isDineInExclusive ? (
                <div className="item-limit">
                  <span
                    style={{ backgroundColor: "#2196F3" }}
                    className="label label-info label-inline font-weight-bold mr-2"
                  >
                    DINE-IN EXCLUSIVE
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="hide-on-mobile">
              {available && (
                <CustomOutlineButton
                  button_color={button_color}
                  button_text_color={button_text_color}
                  variant="custom"
                  className="br-0 btn btn-sm"
                  data-id={_id}
                  disabled={isMenuPreview}
                  onClick={onClickItemDisplay}
                >
                  {" "}
                  Add{" "}
                </CustomOutlineButton>
              )}
            </div>
          </div>
          {!KTUtil.isMobileDevice() ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1rem",
              }}
            >
              <div style={{ flexGrow: 1 }}></div>
              <div
                style={{ display: "flex", flexDirection: "row", flexGrow: 6 }}
              >
                {activeTags}
              </div>
            </div>
          ) : null}
        </div>

        {
          KTUtil.isMobileDevice() && (
            // <div className="row item-tags-mobile">
            <div
              className={`item-tags row ${
                KTUtil.isMobileDevice() ? "pl-4" : "pl-0"
              }`}
              style={KTUtil.isMobileDevice() ? null : { marginLeft: "-7.4%" }}
            >
              {activeTags}
            </div>
          )
          // </div>
        }
      </div>
    )
  );
}
