import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { toPesoAmount } from "../../../helpers";

export const PromoContainer = ({
  promos = [],
  onPromoSelect,
  promo_code = "",
}) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (!isEmpty(promo_code) && !isEmpty(promos)) {
      const defaultInputValue = promos.filter(
        (promo) => promo.label === promo_code,
      );
      if (defaultInputValue.length) {
        setSelected(defaultInputValue);
        onPromoSelect(
          defaultInputValue.length > 0
            ? defaultInputValue[0].value
            : defaultInputValue,
        );
      }
    }
  }, [promo_code, promos]);

  return (
    <>
      <Card.Title className="pb-0 mb-0">
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-row align-items-center justify-content-space-between ml-8 mt-8 mr-8 mb-0">
            <h3 className="font-weight-bolder">
              <i className="fas fa-tags mr-2" style={{ color: "#14C49B" }} />
              Promo Code
            </h3>
            <div className="d-flex align-items-center">
              <a
                className="btn btn-sm"
                style={{
                  color: "#464e5f",
                  textDecoration: "underline",
                }}
                href={`/promos/new`}
                target="_blank"
                rel="noreferrer"
              >
                + New
              </a>
            </div>
          </div>
        </div>
      </Card.Title>
      <Card.Body className="px-8 py-4">
        <Typeahead
          clearButton
          style={{ width: "100%", marginBottom: 10 }}
          id={`basic-typeahead-single`}
          placeholder={`Select promo to add`}
          options={promos}
          onChange={(selected) => {
            setSelected(selected);
            onPromoSelect(selected.length > 0 ? selected[0].value : selected);
          }}
          selected={selected}
        />
        {!isEmpty(selected) && (
          <div className="d-flex flex-row justify-content-space-between">
            <div className="text-uppercase font-weight-bolder">
              Promo value:
            </div>
            <div className="text-uppercase font-weight-bolder text-danger">
              {selected[0].value.promo_type === "free_delivery"
                ? "FREE DELIVERY"
                : selected[0].value.promo_type === "amount_off"
                ? toPesoAmount(selected[0].value[selected[0].value.promo_type])
                : selected[0].value[selected[0].value.promo_type] + "% OFF"}
            </div>
          </div>
        )}
      </Card.Body>
    </>
  );
};
