import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { FormItem } from "../../components/Base";
import { Button, Form, Card } from "react-bootstrap";
import { GetSubmitClassList } from "../../helpers";

// function getBtnVariant(condition = false) {
//     return `btn btn-icon btn-${condition ? "primary" : "outline-primary"}`
// }

// function getStyle (font_type = "normal") {
//     let style = { fontSize: "30px", fontWeight: 600 };

//     if(font_type === "small") style.fontSize = "20px";
//     else if(font_type === "large") style.fontSize = "40px";

//     return style;
// }

export default class WebsiteContentForm extends ProSourceForm {
  constructor(props) {
    super(props);

    const { values = {} } = this.props,
      {
        highlighted_text_1 = "",
        highlighted_text_2 = "",
        highlighted_text_3 = "",
        highlighted_text_4 = "",
        paragraph_1 = "",
        paragraph_2 = "",
        paragraph_3 = "",
        paragraph_4 = "",
        order_type_line = "",
        brand_intro = "",
        brand_logo_orientation = "",
        brand_name_orientation = "hidden",
        brand_name_font_type = "normal",
        store_title_text = "",
        store_title_font_size = "",
        store_title_font_colour = "",
        store_title_alignment = "",
      } = values;

    this.state = {
      values: {
        highlighted_text_1,
        highlighted_text_2,
        highlighted_text_3,
        highlighted_text_4,
        paragraph_1,
        paragraph_2,
        paragraph_3,
        paragraph_4,
        order_type_line,
        brand_intro,
        brand_logo_orientation,
        brand_name_orientation,
        brand_name_font_type,
        store_title_text,
        store_title_font_size,
        store_title_font_colour,
        store_title_alignment,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {};

    this.onClickOrientationChange = this.onClickOrientationChange.bind(this);
  }

  onClickOrientationChange = (orientation = "", field_name = "") => {
    let { values = {} } = this.state;
    values[field_name] = orientation;
    this.setState({ values });
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <Card className="gutter-b card-custom">
        <Card.Header>
          <Card.Title>Home Page Content</Card.Title>
        </Card.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Card.Body>
            <FormItem
              label={
                this.props.groupUrl !== undefined
                  ? "Group Intro"
                  : "Brand Intro"
              }
              name={"brand_intro"}
              inputProps={{
                name: "brand_intro",
                placeholder:
                  this.props.groupUrl !== undefined
                    ? "Group Intro"
                    : "Brand Intro",
                value: this.state.values["brand_intro"],
              }}
              inputActions={inputActions}
              actions={actions}
              showRequired={false}
              normal
            />

            <FormItem
              label={"Highlighted Text 1"}
              name={"highlighted_text_1"}
              inputProps={{
                name: "highlighted_text_1",
                placeholder: "Highlighted Text 1",
                value: this.state.values["highlighted_text_1"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={false}
              normal
            />

            <FormItem
              label={"Paragraph 1"}
              name={"paragraph_1"}
              inputProps={{
                name: "paragraph_1",
                placeholder: "Paragraph 1",
                value: this.state.values["paragraph_1"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"textarea"}
              showRequired={false}
              normal
            />

            <FormItem
              label={"Highlighted Text 2"}
              name={"highlighted_text_2"}
              inputProps={{
                name: "highlighted_text_2",
                placeholder: "Highlighted Text 2",
                value: this.state.values["highlighted_text_2"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={false}
              normal
            />

            <FormItem
              label={"Paragraph 2"}
              name={"paragraph_2"}
              inputProps={{
                name: "paragraph_2",
                placeholder: "Paragraph 2",
                value: this.state.values["paragraph_2"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"textarea"}
              showRequired={false}
              normal
            />

            <FormItem
              label={"Highlighted Text 3"}
              name={"highlighted_text_3"}
              inputProps={{
                name: "highlighted_text_3",
                placeholder: "Highlighted Text 3",
                value: this.state.values["highlighted_text_3"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={false}
              normal
            />

            <FormItem
              label={"Paragraph 3"}
              name={"paragraph_3"}
              inputProps={{
                name: "paragraph_3",
                placeholder: "Paragraph 3",
                value: this.state.values["paragraph_3"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"textarea"}
              showRequired={false}
              normal
            />

            <FormItem
              label={"Highlighted Text 4"}
              name={"highlighted_text_4"}
              inputProps={{
                name: "highlighted_text_4",
                placeholder: "Highlighted Text 4",
                value: this.state.values["highlighted_text_4"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={false}
              normal
            />

            <FormItem
              label={"Paragraph 4"}
              name={"paragraph_4"}
              inputProps={{
                name: "paragraph_4",
                placeholder: "Paragraph 4",
                value: this.state.values["paragraph_4"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"textarea"}
              showRequired={false}
              normal
            />

            {this.props.groupUrl === undefined ? (
              <FormItem
                label={"Order Type Line"}
                name={"order_type_line"}
                inputProps={{
                  name: "order_type_line",
                  placeholder: "Order Type Line",
                  value: this.state.values["order_type_line"],
                }}
                inputActions={inputActions}
                actions={actions}
                showRequired={false}
                normal
              />
            ) : null}
          </Card.Body>
          <Card.Footer>
            <Button
              variant="primary"
              type="submit"
              className={GetSubmitClassList(this.state.isSubmitting, "")}
            >
              Save Changes
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    );
  }
}
