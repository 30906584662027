import React from "react";
import WebsiteContentForm from "./forms/WebsiteContentForm";

export default class HomepageContentFormDiv extends React.Component {
  render() {
    const { data = {} } = this.props;

    return (
      <>
        <WebsiteContentForm
          values={data.website_content}
          onSubmit={this.props.onSubmitWebsiteContentForm}
          groupUrl={data.group_url}
        />
      </>
    );
  }
}
