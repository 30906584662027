import { isEmpty } from "lodash";
import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { pluralize, toPesoAmount } from "../../../helpers";
import styled from "styled-components";
import { ExtraGroupModal } from "./ExtraGroupModal";
import { RequiredAsterisk } from "../../../components/Base";

export default function CreateCartItems({
  menu = [],
  isLoading = false,
  onCartUpdate,
  onAddItem,
}) {
  const [searchText, setSearchText] = useState("");
  const [showItemModal, setShowItemModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const filteredItems =
    searchText !== ""
      ? menu.filter((item) => {
          return (
            item.item_id.name
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1
          );
        })
      : menu;

  const onAddToCart = (item) => {
    if (item) {
      onCartUpdate(item, true);
    }
  };

  return (
    <>
      <Card.Title className="pb-0 mb-2">
        <div className="d-flex flex-row justify-content-space-between">
          <div className="d-flex flex-row justify-content-center">
            <h3 className="ml-8 mt-8 font-weight-bolder">
              Add Items <RequiredAsterisk />
            </h3>
            <div className="d-flex mt-4 align-items-center">
              <a
                className="btn btn-sm"
                style={{
                  color: "#464e5f",
                  textDecoration: "underline",
                }}
                onClick={onAddItem}
              >
                + New
              </a>
            </div>
          </div>
          <input
            type="text"
            className="form-control mt-6 mr-8"
            style={{ width: 200 }}
            placeholder="Search"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          />
        </div>
      </Card.Title>
      <Card.Body className="d-flex p-5 justify-content-center">
        {isLoading ? (
          <Row className="font-weight-bolder w-100 justify-content-center align-items-center">
            Loading concierge items...
          </Row>
        ) : isEmpty(menu) ? (
          <Row className="font-weight-bolder w-100 justify-content-center align-items-center">
            Your store doesn't have concierge items
          </Row>
        ) : (
          <Row
            className="m-0 justify-content-space-between "
            style={{ width: "99%" }}
          >
            {filteredItems.map((item) => {
              return (
                <MenuCard
                  key={`item-${item._id}`}
                  item={item}
                  onCartUpdate={onCartUpdate}
                  onShowItemModal={(value, item) => {
                    const selected_item = menu.filter(
                      (menu) => menu._id === item._id,
                    )[0];
                    setShowItemModal(value);
                    setSelectedItem(selected_item);
                  }}
                  setSelectedItem={setSelectedItem}
                  onAddToCart={onAddToCart}
                />
              );
            })}
          </Row>
        )}
      </Card.Body>
      {showItemModal && (
        <ExtraGroupModal
          show={showItemModal}
          setShowItemModal={setShowItemModal}
          item={selectedItem}
          onCartUpdate={onCartUpdate}
        />
      )}
    </>
  );
}

const MenuCard = ({ item = {}, onShowItemModal, onAddToCart }) => {
  const { item_id = {}, available, remaining_items } = item;
  const IMG_PLACEHOLDER =
    "https://pickupph.s3.ap-southeast-1.amazonaws.com/assets/pickup-default-green.jpg";
  return (
    <CustomCard>
      <Row className="m-0">
        <Col className="d-flex flex-grow-0 align-items-center">
          <StyledImage
            src={item_id.image_url || IMG_PLACEHOLDER}
            alt={item_id.name}
            onError={(ev) => {
              ev.target.onError = null;
              ev.target.src = item_id.image_url;
            }}
          />
        </Col>
        <div
          className="d-flex flex-column justify-content-space-between"
          style={{ flexBasis: "55%" }}
        >
          <div className="d-flex flex-column">
            <Row className="m-0">
              <div
                className="text-left font-weight-bolder text-dark"
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item_id.name}
              </div>
            </Row>
            <Row className="m-0">
              {item_id.active_discount > 0 ? (
                <div className="item-price">
                  <del>{toPesoAmount(item_id.regular_price)}</del>
                  <span className="ml-1 text-danger font-weight-boldest">
                    {toPesoAmount(item_id.price)}
                  </span>
                </div>
              ) : (
                <div className="item-price">{toPesoAmount(item_id.price)}</div>
              )}
            </Row>
            {available && (
              <Row className="m-0">
                <div className="item-limit">
                  {remaining_items <= 10 && remaining_items > -1 && (
                    <span className="text-danger">
                      {" "}
                      {pluralize(remaining_items, "item")} left!
                    </span>
                  )}
                </div>
              </Row>
            )}
          </div>
          <Row className="m-0 justify-content-end">
            <Button
              size="sm"
              className="btn-icon btn-circle btn-sm font-weight-boldest"
              variant="primary"
              style={{ fontSize: "0.5rem" }}
              onClick={(e) => {
                e.preventDefault();
                if (item.extragroup.length > 0) {
                  onShowItemModal(true, item);
                } else {
                  onAddToCart(item);
                }
              }}
            >
              <i className="fas fa-plus" style={{ fontSize: 10 }} />
            </Button>
          </Row>
        </div>
      </Row>
    </CustomCard>
  );
};

const StyledImage = styled.img`
  width: 100px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
`;
const CustomCard = styled(Card)`
  display: flex;
  justify-content: center;
  width: 48%;
  height: 100px;
  margin: 3px;
  border-radius: 0;
  margin: 3px;

  &:hover {
    background-color: #14c49b1a;
  }
`;
