import axios from "axios";

import def from "./modules/default";
import iam from "./modules/iam";

const config = require("./core-config");
const environment = process.env.NODE_ENV || "development";
// const environment = "production";

// const https = require("https");
// const http = require("http");

console.log("environment", environment);

function createInstance(apiName) {
  return axios.create({
    baseURL: config[apiName][environment],
    timeout: 180000,
  });
}

function createFileInstance(apiName) {
  return axios.create({
    baseURL: config[apiName][environment],
    timeout: 180000,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

const puinstance = createInstance("pu"),
  pufinstance = createFileInstance("pu");

var CoreAPI = function (apiName = "") {
  return {
    init: function () {},
    get: function () {
      if (apiName === "pu") {
        return def(puinstance);
      } else if (apiName === "iam") {
        return iam(puinstance);
      } else if (apiName === "puf") {
        return def(puinstance);
      }
    },
    setAuthToken: function (AUTH_TOKEN) {
      puinstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${AUTH_TOKEN}`;
      pufinstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${AUTH_TOKEN}`;
    },
    resetAuthToken: function () {
      puinstance.defaults.headers.common["Authorization"] = "";
      pufinstance.defaults.headers.common["Authorization"] = "";
    },
    getBaseURL: function () {
      return config[apiName][environment];
    },
  };
};

export default CoreAPI;
